  import React, { useState, useEffect } from "react";
  import "./Campaigns.css";
  import { MdOutlineDashboard } from "react-icons/md";
  import { FaPlus } from "react-icons/fa";
  import { BsThreeDots } from "react-icons/bs";
  import Header from "../Header/Header";
  import { AiTwotoneThunderbolt } from "react-icons/ai";
  import { SiThunderstore } from "react-icons/si";
  import { MdDelete } from "react-icons/md";
  import Sidebar from "../Siderbar/Sidebar";
  import { useNavigate } from "react-router-dom";
  import axios from "axios";
  import { ensureValidToken } from "../../auth/authUtils";
  import { HiDotsHorizontal } from "react-icons/hi";
  import { FaPause, FaPlay } from "react-icons/fa";
  import { Link } from "react-router-dom";
  import {
    Campaigns_URL,
    Deletecampaigns_URL,
    Editcampaigns_URL,
    Removselected_URL,
    Pause_URL,
    Duplicate_URL,
    ResumeCampaigns_URL,
  } from "../../firebase/config";
  import { FaEdit, FaTrash } from "react-icons/fa";

  function Campaigns() {
    const navigate = useNavigate();
    const [campaignData, setCampaignData] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState(new Set());
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [editCampaignName, setEditCampaignName] = useState("");

    const fetchData = async () => {
      try {
        const token = await ensureValidToken();
        const userId = localStorage.getItem("userId");

        if (!token || !userId) throw new Error("Missing token or user ID");

        const headers = {
          Token: token,
          UserId: userId,
          "Content-Type": "application/json",
        };

        const response = await axios.post(Campaigns_URL, {}, { headers });

        if (response.data.success && response.data.data.length > 0) {
          const campaigns = response.data.data[0].campaignData || [];
          setCampaignData(campaigns);
        } else if (response.data.code === 404) {
          alert(response.data.message || "No campaigns found.");
          setCampaignData([]);
        } else {
          alert(response.data.message || "Failed to load campaigns data.");
        }
      } catch (error) {
        console.error("Data fetch error:", error.message);
        alert("Failed to load campaigns data.");
      }
    };
    const toggleCampaignSelection = (campaignId) => {
      setSelectedCampaigns((prev) => {
        const updated = new Set(prev);
        if (updated.has(campaignId)) {
          updated.delete(campaignId);
        } else {
          updated.add(campaignId);
        }
        setSelectAll(updated.size === campaignData.length);
        return updated;
      });
    };
    const toggleSelectAll = (isChecked) => {
      setSelectAll(isChecked);
      setSelectedCampaigns(
        isChecked
          ? new Set(campaignData.map((campaign) => campaign.campaignId))
          : new Set()
      );
    };
    const handleBulkDelete = async () => {
      if (selectedCampaigns.size === 0) {
        alert("No campaigns selected for deletion.");
        return;
      }

      const confirmDelete = window.confirm(
        "Are you sure you want to delete the selected campaigns?"
      );
      if (!confirmDelete) return;

      try {
        const token = await ensureValidToken();
        const userId = localStorage.getItem("userId");

        if (!token || !userId) throw new Error("Missing token or user ID");

        const headers = {
          Token: token,
          UserId: userId,
          "Content-Type": "application/json",
        };

        const campaignIds = Array.from(selectedCampaigns);

        const response = await axios.post(
          Removselected_URL,
          { campaignIds },
          { headers }
        );

        if (response.data.success) {
          alert(
            response.data.message || "Selected campaigns deleted successfully."
          );
          setCampaignData((prevCampaigns) =>
            prevCampaigns.filter(
              (campaign) => !selectedCampaigns.has(campaign.campaignId)
            )
          );
          setSelectedCampaigns(new Set());
          setSelectAll(false);
        } else {
          alert("Failed to delete selected campaigns on server.");
        }
      } catch (error) {
        console.error("Bulk delete error:", error.message);
        alert("Failed to delete selected campaigns. Please try again.");
      }
    };

    const handleDelete = async (campaignId) => {
      if (!campaignId) {
        console.error("Campaign ID is missing.");
        alert("Failed to delete campaign. Please try again.");
        return;
      }

      const confirmDelete = window.confirm(
        "Are you sure you want to delete this campaign?"
      );
      if (!confirmDelete) return;

      try {
        const token = await ensureValidToken();
        const userId = localStorage.getItem("userId");

        if (!token || !userId) {
          console.error("Token or User ID is missing.");
          throw new Error("Missing token or user ID");
        }

        const headers = {
          Token: token,
          UserId: userId,
          "Content-Type": "application/json",
        };

        const response = await axios.post(
          Deletecampaigns_URL,
          { campaignId },
          { headers }
        );

        if (response.data.success) {
          alert(response.data.message || "Campaign deleted successfully.");
          setCampaignData((prevCampaigns) =>
            prevCampaigns.filter((campaign) => campaign.campaignId !== campaignId)
          );
        } else {
          console.error("Server response error:", response.data.message);
          alert(response.data.message || "Failed to delete campaign on server.");
        }
      } catch (error) {
        console.error("Delete error:", error.message);
        alert("Failed to delete campaign. Please try again.");
      }
    };
    const resetSelectedCampaign = () => {
      setSelectedCampaign(null);
      setEditCampaignName("");
    };

    const handleDuplicate = async () => {
      if (!selectedCampaign || !selectedCampaign.campaignId) {
        alert("Please select a campaign to duplicate.");
        return;
      }

      if (!editCampaignName.trim()) {
        alert("Please enter a valid campaign name.");
        return;
      }

      try {
        const token = await ensureValidToken();
        const userId = localStorage.getItem("userId");

        if (!token || !userId) throw new Error("Missing token or user ID");

        const headers = {
          Token: token,
          UserId: userId,
          "Content-Type": "application/json",
        };

        const requestBody = {
          campaignId: selectedCampaign.campaignId,
          newCampaignName: editCampaignName,
        };

        const response = await axios.post(Duplicate_URL, requestBody, {
          headers,
        });

        if (response.data.success) {
          alert(
            response.data.message || "Duplicate campaign created successfully."
          );

          // Fetch latest campaigns data to ensure state reflects changes
          fetchData();

          // Close the modal
          resetSelectedCampaign();
          const modal = document.getElementById("duplicateCampaignModal");
          const bootstrapModal = window.bootstrap.Modal.getInstance(modal);
          bootstrapModal.hide();
        } else {
          alert(response.data.message || "Failed to duplicate campaign.");
        }
      } catch (error) {
        console.error("Duplicate error:", error.message);
        alert("Failed to duplicate campaign. Please try again.");
      }
    };

    const handleEdit = async (event) => {
      event.preventDefault();
      if (!selectedCampaign) {
        alert("Please select a campaign to edit.");
        return;
      }

      try {
        const token = await ensureValidToken();
        const userId = localStorage.getItem("userId");

        if (!token || !userId) throw new Error("Missing token or user ID");

        const headers = {
          Token: token,
          UserId: userId,
          "Content-Type": "application/json",
        };

        const response = await axios.post(
          Editcampaigns_URL,
          {
            campaignId: selectedCampaign.campaignId,
            campaignName: editCampaignName,
          },
          { headers }
        );

        if (response.data.success) {
          alert(response.data.message || "Campaign updated successfully.");
          setCampaignData((prevCampaigns) =>
            prevCampaigns.map((campaign) =>
              campaign.campaignId === selectedCampaign.campaignId
                ? { ...campaign, campaignName: editCampaignName }
                : campaign
            )
          );
          setSelectedCampaign(null);
          setEditCampaignName("");
        } else {
          alert("Failed to update campaign on server.");
        }
      } catch (error) {
        console.error("Edit error:", error.message);
        alert("Failed to update campaign. Please try again.");
      }
    };

    const handlePauseToggle = async (campaignId, currentStatus) => {
      try {
        const token = await ensureValidToken();
        const userId = localStorage.getItem("userId");

        if (!token || !userId) throw new Error("Missing token or user ID");

        const headers = {
          Token: token,
          UserId: userId,
          "Content-Type": "application/json",
        };

        const apiURL =
          currentStatus === "Paused" ? ResumeCampaigns_URL : Pause_URL;

        const response = await axios.post(apiURL, { campaignId }, { headers });

        if (response.data.success) {
          alert(response.data.message || "Campaign status updated successfully.");
          setCampaignData((prevCampaigns) =>
            prevCampaigns.map((campaign) =>
              campaign.campaignId === campaignId
                ? {
                    ...campaign,
                    campaignStatus:
                      currentStatus === "Paused" ? "Active" : "Paused",
                  }
                : campaign
            )
          );
        } else {
          alert(response.data.message || "Failed to update campaign status.");
        }
      } catch (error) {
        console.error("Pause/Resume error:", error.message);
        alert("Failed to update campaign status. Please try again.");
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <div>
        <Header title="CAMPAIGNS" />
        <Sidebar />

        <div className="email-container" style={{ marginLeft: "230px" }}>
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center gap-3">
              <div className="dropdown mt-4">
                <button
                  className="btn btn-white fw-bold shadow dropdown-toggle gap-5 btn-haserror text-Black rounded"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <AiTwotoneThunderbolt className="fs-6 text-danger" />
                  <span className="mx-2 fw-medium">All Status</span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <button className="dropdown-item">All Status</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Paused</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Has errors</button>
                  </li>
                  <li>
                    <button className="dropdown-item">
                      No custom tracking domain
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item">Warm up active</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Warm up paused</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Warm up has error</button>
                  </li>
                </ul>
              </div>
              <div className="dropdown mt-4">
                <button
                  className="btn btn-white fw-bold shadow dropdown-toggle gap-5 btn-haserror text-Black rounded"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <SiThunderstore className="fs-6 text-danger" />
                  <span className="mx-2 fw-medium">Newest Status</span>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <li>
                    <button className="dropdown-item">Newest Status</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Paused</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Has errors</button>
                  </li>
                  <li>
                    <button className="dropdown-item">
                      No custom tracking domain
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item">Warm up active</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Warm up paused</button>
                  </li>
                  <li>
                    <button className="dropdown-item">Warm up has error</button>
                  </li>
                </ul>
              </div>

              {selectedCampaigns.size > 0 && (
                <div className="fs-5 mt-3 text-danger">
                  <FaTrash
                    className="text-danger cursor-pointer"
                    onClick={handleBulkDelete}
                  />
                </div>
              )}

              <button
                className="btn btn-white fw-bold shadow gap-5 mt-4 btn-haserror text-white rounded bg-primary"
                onClick={() => navigate("/add-campaigns")}
              >
                <FaPlus className="fs-6" />
                <span className="mx-2 fw-medium">Add New</span>
              </button>
            </div>
          </div>

          <div className="container mt-3  p-3 rounded">
            {" "}
            <div className="input-group search" style={{ width: "200px" }}>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
            </div>
            <table className="mt-2 table table-striped">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      id="select_all"
                      checked={selectAll} // Bind to selectAll state
                      onChange={(e) => toggleSelectAll(e.target.checked)} // Handle "Select All" changes
                    />
                    <span
                      className="text text-secondary fs-6 fw-medium"
                      style={{ paddingLeft: "7px" }}
                    >
                      NAME
                    </span>
                  </th>
                  <th className="text-secondary fs-6 fw-medium">STATUS</th>
                  <th className="text-secondary fs-6 fw-medium">PROGRESS</th>
                  <th className="text-secondary fs-6 fw-medium">SENT</th>
                  <th className="text-secondary fs-6 fw-medium">CLICK</th>
                  <th className="text-secondary fs-6 fw-medium">REPLIED</th>
                  <th className="text-secondary fs-6 fw-medium">OPPORTUNITIES</th>
                  <th className="text-secondary fs-6 fw-medium"></th>
                  <th className="text-secondary fs-6 fw-medium"></th>
                </tr>
              </thead>
              <tbody>
                {campaignData.map((campaign) => (
                  <tr key={campaign.campaignId}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedCampaigns.has(campaign.campaignId)} // Check if the campaign is selected
                        onChange={() =>
                          toggleCampaignSelection(campaign.campaignId)
                        }
                      />
                      <Link
                        to={`/campaigns/${campaign.campaignId}`}
                        className="pl-2 text fs-6"
                        style={{
                          paddingLeft: "7px",
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        {campaign.campaignName}
                      </Link>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn text-white bg-black btn-draft rounded-pill fw-medium"
                        style={{
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0",
                          width: "85px",
                          fontSize: "small",
                        }}
                      >
                        {campaign.campaignStatus}
                      </button>
                    </td>
                    <td>{campaign.progressPercentage}%</td>
                    <td>{campaign.emailsSentCount}</td>
                    <td>{campaign.emailsClickedCount}</td>
                    <td>{campaign.emailsRepliedCount}</td>
                    <td>{campaign.opportunitiesCount}</td>
                    <td className="text-success flex">
                      <FaEdit
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        aria-controls="editModal"
                        onClick={() => setSelectedCampaign(campaign)}
                      />
                      <MdDelete
                        onClick={() => handleDelete(campaign.campaignId)}
                      />
                      <AiTwotoneThunderbolt
                        type="button"
                        className="cursor-pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#duplicateCampaignModal"
                        onClick={() => {
                          if (!campaign.campaignId) {
                            alert("Invalid campaign selected.");
                            return;
                          }
                          setSelectedCampaign(campaign); // Ensure the correct campaign is selected
                          setEditCampaignName(""); // Clear any previously entered name
                        }}
                      />
                    </td>

                    <td className="gap-4">
                      {campaign.campaignStatus === "Paused" ? (
                        <FaPlay
                          onClick={() =>
                            handlePauseToggle(campaign.campaignId, "Paused")
                          }
                          className="cursor-pointer text-success"
                        />
                      ) : (
                        <FaPause
                          onClick={() =>
                            handlePauseToggle(campaign.campaignId, "Active")
                          }
                          className="cursor-pointer text-danger"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              className="modal fade"
              id="editModal"
              tabIndex="-1"
              aria-labelledby="editModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="editModalLabel">
                      Edit Campaign
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <form onSubmit={handleEdit}>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label htmlFor="editInput" className="form-label">
                          Rename campaign
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="editInput"
                          value={editCampaignName}
                          onChange={(e) => setEditCampaignName(e.target.value)}
                          placeholder="Enter new campaign name"
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="duplicateCampaignModal"
              tabIndex="-1"
              aria-labelledby="duplicateCampaignModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="duplicateCampaignModalLabel">
                      Duplicate Campaign
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={resetSelectedCampaign}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <label htmlFor="newCampaignName" className="form-label">
                      New Campaign Name
                    </label>
                    <input
                      type="text"
                      id="newCampaignName"
                      className="form-control"
                      placeholder="Enter new name"
                      value={editCampaignName}
                      onChange={(e) => setEditCampaignName(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={resetSelectedCampaign}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={handleDuplicate}
                    >
                      Duplicate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default Campaigns;
