import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ensureValidToken } from "../../auth/authUtils";
import { LeadList_URL } from "../../firebase/config";
import { MdDelete } from "react-icons/md";

function Lead() {
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const body = { campaignId: "1" }; // Replace with actual campaignId if dynamic

      const response = await axios.post(LeadList_URL, body, { headers });

      if (response.data.success && response.data.data) {
        setLeads(response.data.data[0]?.leadsData || []);
      } else {
        throw new Error("No lead data available.");
      }
    } catch (error) {
      console.error(
        "Data fetch error:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to load leads. Please check your token and try again.");
    }
  };

  const deleteSelectedLeads = async () => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const body = {
        campaignId: "1",
        leadIds: selectedLeads,
      };

      const response = await axios.post(`${LeadList_URL}/delete`, body, {
        headers,
      });

      if (response.data.success) {
        alert("Leads removed successfully.");
        setLeads(leads.filter((lead) => !selectedLeads.includes(lead.leadId)));
        setSelectedLeads([]);
      } else {
        throw new Error(response.data.message || "Failed to delete leads.");
      }
    } catch (error) {
      console.error(
        "Delete error:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to delete selected leads. Please try again.");
    }
  };

  const deleteLead = async (leadId) => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
      const body = {
        campaignId: "1", 
        leadIds: [leadId],
      };

      const response = await axios.post(`${LeadList_URL}/delete`, body, {
        headers,
      });

      if (response.data.success) {
        alert("Lead removed successfully.");
        setLeads(leads.filter((lead) => lead.leadId !== leadId));
      } else {
        throw new Error(response.data.message || "Failed to delete the lead.");
      }
    } catch (error) {
      console.error(
        "Delete error:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to delete the lead. Please try again.");
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedLeads(leads.map((lead) => lead.leadId));
    } else {
      setSelectedLeads([]);
    }
  };

  const handleSelectLead = (leadId) => {
    if (selectedLeads.includes(leadId)) {
      setSelectedLeads(selectedLeads.filter((id) => id !== leadId));
    } else {
      setSelectedLeads([...selectedLeads, leadId]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-white p-3">
      <div className="d-flex justify-content-center flex-column align-items-center mt-4">
        {leads.length === 0 && (
          <>
            <img
              alt="No Leads"
              className="logo"
              src="https://static.wixstatic.com/media/4d38c6_51d5cfb779554e699505a399a48871c5~mv2.png/v1/fill/w_443,h_341,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/pixeltrue-plan-1%201.png"
            />
            <span className="mt-3 fw-medium fs-4">
              👋 Add some leads to get started
            </span>
          </>
        )}
        <div className="mt-3 float-right">
          <button
            type="button"
            className="btn btn-primary shadow fw-medium me-2 border"
            onClick={() => navigate("/upload-lead-document")}
          >
            Add leads
          </button>
          {selectedLeads.length > 0 && (
            <button
              type="button"
              className="btn btn-danger shadow fw-medium me-2 border"
              onClick={deleteSelectedLeads}
            >
              <MdDelete className="me-2" />
              Delete selected
            </button>
          )}
        </div>

        <div className="mt-4 w-100">
          <table className="table table-bordered table-striped">
            <thead className="table-dark">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    id="select_all"
                    className="mr-2"
                    checked={
                      leads.length > 0 && selectedLeads.length === leads.length
                    }
                    onChange={handleSelectAll}
                  />
                  Email Address
                </th>
                <th>Email Provider</th>
                <th>Contact</th>
                <th>Company Name</th>
                <th>Lead Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {leads.length > 0 ? (
                leads.map((lead) => (
                  <tr key={lead.leadId}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedLeads.includes(lead.leadId)}
                        onChange={() => handleSelectLead(lead.leadId)}
                      />
                      {lead.emailAddress}
                    </td>
                    <td>{lead.emailProvider}</td>
                    <td>{lead.contact}</td>
                    <td>{lead.companyName}</td>
                    <td>{lead.leadStatus}</td>
                    <td>
                      <MdDelete
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => deleteLead(lead.leadId)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No leads available. Please add some leads.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Lead;
