import React, { useState } from "react";
import BackHeader from "../BackHeader/BackHeader";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaUpload } from "react-icons/fa";
import UploadData from "../UploadData/UploadData";
import "./Upload.css";

function Upload() {
  const navigate = useNavigate();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const lines = text.split("\n").slice(1); // Skip header row
        const data = lines.map((line) => {
          const [
            email,
            firstName,
            lastName,
            imapUsername,
            imapPassword,
            imapHost,
            imapPort,
            smtpUsername,
            smtpPassword,
            smtpHost,
            smtpPort,
            altSmtpPort,
            dailylimit,
            warmupEnabled,
            warmupLimit,
            warmupIncrement,
          ] = line.split(",").map((field) => field?.trim() || "");

          return {
            email,
            firstName,
            lastName,
            imapUsername,
            imapPassword,
            imapHost,
            imapPort,
            smtpUsername,
            smtpPassword,
            smtpHost,
            smtpPort,
            altSmtpPort,
            dailylimit,
            warmupEnabled,
            warmupLimit,
            warmupIncrement,
          };
        });

        console.log("Parsed Data Array:", data);
        setUploadedData(data);
        setFileUploaded(true);
      };
      reader.readAsText(file);
    }
  };

  const navigateBack = () => {
    navigate("/addacounts");
  };

  return (
    <div>
      <BackHeader />
      <div className="text-center mt-4">
        <div>
          <span className="back-button" onClick={navigateBack}>
            <IoMdArrowBack />
          </span>
          <div className="provider-heading fw-medium">
            Select another provider
          </div>
        </div>
        <div className="container mt-5">
          <div className="text-center mb-4 fs-5 fw-medium">Upload CSV File</div>
          <div className="form-box">
            <form encType="multipart/form-data" className="gap-3">
              <div className="form-group fs-2">
                <FaUpload />
              </div>
              <label htmlFor="csvFile" className="mt-3 fs-6 fw-medium">
                CLICK TO UPLOAD
              </label>
              <input
                type="file"
                id="csvFile"
                name="csvFile"
                className="form-control"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="gallery mt-4">
                <button
                  type="button"
                  className="btn btn-primary fw-medium"
                  onClick={() => document.getElementById("csvFile").click()}
                >
                  Upload
                </button>
              </div>
            </form>
          </div>
          {fileUploaded && <UploadData data={uploadedData} />}
        </div>
      </div>
    </div>
  );
}

export default Upload;
