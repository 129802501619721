import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import PrivateRoute from "./components/Auth/ProtectedRoute";
import Login from "./components/Signin/Login";
import SignUp from "./components/SignUp/SignUp";
import EmailPage from "./components/EmailPage/EmailPage";
import Campaigns from "./components/Campaigns/Campaigns";
import AddAccounts from "./components/AddAccounts/AddAccount";
import "./App.css";
import AnyProvider from "./components/AnyProvider/AnyProvider";
import Upload from "./components/Upload/Upload";
import Analytics from "./components/Analytics/Analytics";
import LeadManagement from "./components/LeadManagement/LeadManagement";
import UniBox from "./components/UniBox/UniBox";
import AddCampaigns from "./components/AddCampaigns/AddCampaigns";
import MyCampaigns from "./components/MyCampaigns/MyCampaigns";
import UpLoadLead from "./components/UploadLead.js/UpLoadLead";
import UploadDocument from "./components/UpLoadDocLead/UploadDocument";
import CampaignsTab from "./components/CampaignsTab/CampaignsTab";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const storedLoginState = localStorage.getItem("isLoggedIn");
      if (storedLoginState) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }

      setIsLoading(false);
    };
    checkLoginStatus();
  }, [navigate]);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    navigate("/email");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("user");
    navigate("/");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path="/email"
          element={
            <PrivateRoute>
              <EmailPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns"
          element={
            <PrivateRoute>
              <Campaigns />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-accounts"
          element={
            <PrivateRoute>
              <AddAccounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/anyprovider"
          element={
            <PrivateRoute>
              <AnyProvider />
            </PrivateRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <PrivateRoute>
              <Upload />
            </PrivateRoute>
          }
        />
        <Route
          path="/analytics"
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        />
        <Route
          path="/management"
          element={
            <PrivateRoute>
              <LeadManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/unibox"
          element={
            <PrivateRoute>
              <UniBox />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-campaigns"
          element={
            <PrivateRoute>
              <AddCampaigns />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-campaigns"
          element={
            <PrivateRoute>
              <MyCampaigns />
            </PrivateRoute>
          }
        />
        <Route
          path="/upload-lead"
          element={
            <PrivateRoute>
              <UpLoadLead />
            </PrivateRoute>
          }
        />
        <Route
          path="/upload-lead-document"
          element={
            <PrivateRoute>
              <UploadDocument />
            </PrivateRoute>
          }
        />
           <Route path="/campaigns/:id" element={<CampaignsTab />} />
      </Routes>
    </div>
  );
}

export default App;
