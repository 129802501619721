import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";
import { LeadImport_URL } from "../../firebase/config";
import { ensureValidToken } from "../../auth/authUtils";

const emailOptions = [
  { value: "Do not import", label: "Do not import" },
  { value: "Email", label: "Email" },
  { value: "First Name", label: "First Name" },
  { value: "Last Name", label: "Last Name" },
  { value: "Daily Limit", label: "Daily Limit" },
  { value: "IMAP Username", label: "IMAP Username" },
  { value: "IMAP Password", label: "IMAP Password" },
  { value: "IMAP Host", label: "IMAP Host" },
  { value: "IMAP Port", label: "IMAP Port" },
  { value: "SMTP Username", label: "SMTP Username" },
  { value: "SMTP Password", label: "SMTP Password" },
  { value: "SMTP Host", label: "SMTP Host" },
  { value: "SMTP Port", label: "SMTP Port" },
  { value: "Warmup Enabled", label: "Warmup Enabled" },
  { value: "Warmup Limit", label: "Warmup Limit" },
  { value: "Warmup Increment", label: "Warmup Increment" },
];

function UploadLeadData({ data }) {
  const [uploadStatuses, setUploadStatuses] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  // Filter out rows with no email address
  const filteredData = data.filter((row) => row.Name);

  const defaultOption = emailOptions.find(
    (option) => option.value === "Do not import"
  );
  const initialSelectedValues = Object.keys(filteredData[0] || {}).reduce(
    (acc, key) => {
      acc[key] = defaultOption;
      return acc;
    },
    {}
  );

  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

  const handleSelectChange = (field, selectedOption) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: selectedOption,
    }));
  };

  const handleUpload = async () => {
    setIsUploading(true);
    let token = await ensureValidToken();
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      console.log("No valid token or userId. Aborting upload.");
      setIsUploading(false);
      return;
    }

    const headers = {
      Token: token,
      UserId: userId,
      "Content-Type": "application/json",
    };

    if (!filteredData || filteredData.length === 0) {
      console.log("No data to upload.");
      setIsUploading(false);
      return;
    }

    for (let index = 0; index < filteredData.length; index++) {
      setUploadStatuses((prevStatuses) => ({
        ...prevStatuses,
        [index]: { isLoading: true },
      }));

      const row = filteredData[index];
      const apiPayload = {
        campaignId: "1", // Static campaignId as specified
        emailAddress: row.Name, // Adjust as needed to match actual email field
        fullName: row.Status,
        companyName: row.Market,
      };

      try {
        const response = await axios.post(LeadImport_URL, apiPayload, {
          headers,
        });
        
        // Extracting status and error for the specific email from the response
        const responseData = response.data?.data?.[0];
        const statusMessage = responseData?.status || "Success";
        const tooltipMessage = responseData?.error || "";

        setUploadStatuses((prevStatuses) => ({
          ...prevStatuses,
          [index]: {
            message: statusMessage,
            tooltip: tooltipMessage,
            isLoading: false,
          },
        }));
      } catch (error) {
        console.error("API Error:", error.response || error.message);
        setUploadStatuses((prevStatuses) => ({
          ...prevStatuses,
          [index]: {
            message: "Failed",
            tooltip:
              error.response?.data?.data?.[0]?.error ||
              error.message ||
              "Unknown Error",
            isLoading: false,
          },
        }));
      }
    }
    setIsUploading(false);
  };

  return (
    <div className="mt-5">
      <table className="table bg-black">
        <thead>
          <tr>
            <th>Column Name</th>
            <th>Select Type</th>
            <th>Samples</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            Object.keys(filteredData[0]).map((key, index) => (
              <tr key={index}>
                <th scope="row">{key}</th>
                <td>
                  <Select
                    options={emailOptions}
                    value={selectedValues[key]}
                    onChange={(option) => handleSelectChange(key, option)}
                  />
                </td>
                <td>{filteredData[0][key] || "No Data"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No Data Available</td>
            </tr>
          )}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-success"
        onClick={handleUpload}
        disabled={isUploading}
      >
        {isUploading ? "Uploading..." : "Upload"}
      </button>

      <table className="table border mt-4">
        <thead>
          <tr>
            <th>Email Address</th>
            <th>Full Name</th>
            <th>Company Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              <th scope="row">{row.Name}</th>
              <td>{row.Status}</td>
              <td>{row.Market}</td>
              <td
                title={uploadStatuses[index]?.tooltip || ""}
                style={{
                  cursor: uploadStatuses[index]?.tooltip
                    ? "pointer"
                    : "default",
                }}
              >
                {uploadStatuses[index]?.isLoading ? (
                  <div className="spinner"></div>
                ) : (
                  uploadStatuses[index]?.message || "Pending"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UploadLeadData;
