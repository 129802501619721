import React, { useState } from "react";
import BackHeader from "../BackHeader/BackHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AddCampaigns.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Addcampaigns_URL } from "../../firebase/config";
import { ensureValidToken } from "../../auth/authUtils";

function AddCampaigns() {
  const navigate = useNavigate();
  const [campaignName, setCampaignName] = useState("");

  const createCampaign = async () => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };

      const body = {
        campaignName: campaignName,
      };

      const response = await axios.post(Addcampaigns_URL, body, { headers });

      if (response.data.code === 200 && response.data.success) {
        alert(response.data.message);
        navigate("/my-campaigns");
      } else {
        alert("Failed to save the campaign.");
      }
    } catch (error) {
      console.error("Error creating campaign:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleContinue = async () => {
    if (campaignName.trim()) {
      // Create a campaign if the name is provided
      await createCampaign();
    } else {
      // Navigate directly if no campaign name is provided
      navigate("/my-campaigns");
    }
  };

  const handleSkip = () => {
    // Directly navigate without creating a campaign
    navigate("/my-campaigns");
  };

  return (
    <div>
      <BackHeader />
      <div className="d-flex flex-column justify-content-center align-items-center mt-5">
        <div className="w-75 p-4">
          <h1 className="text-secondary fw-medium">
            Let's create a new campaign!
          </h1>
          <p className="text-secondary fs-4 fw-medium mt-3">
            What would you like to name it?
          </p>
          <div className="mt-2 w-50">
            <label className="text-black fw-medium">Campaign Name</label>
            <textarea
              className="form-control mt-1"
              id="description"
              rows="3"
              placeholder="My Campaign"
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
            ></textarea>
          </div>
          <div className="mt-5 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary me-4"
              onClick={handleSkip}
            >
              Skip
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCampaigns;
