import React, { useState, useEffect } from "react";
import "./Option.css";
import { MdSaveAlt } from "react-icons/md";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import OptionDropdown from "../OptionDropdown/OptionDropdown";
import { ensureValidToken } from "../../auth/authUtils";
import { LeadsEmailList_URL, OptionView_URL } from "../../firebase/config";
import axios from "axios";

function Option() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [emailAccounts, setEmailAccounts] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState({}); // To store OptionView_URL data

  // Fetch email accounts
  const fetchData = async () => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");

      if (!token || !userId) throw new Error("Missing token or user ID");

      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };

      const response = await axios.post(LeadsEmailList_URL, {}, { headers });

      if (response.data.success && response.data.data.length > 0) {
        const accounts = response.data.data[0].emailAccounts || [];
        setEmailAccounts(accounts);
      } else {
        throw new Error("No email accounts data available.");
      }
    } catch (error) {
      console.error(
        "Data fetch error:",
        error.response ? error.response.data : error.message
      );
      alert(
        "Failed to load email accounts. Please check your token and try again."
      );
    }
  };

  // Fetch campaign options from OptionView_URL
  const fetchCampaignOptions = async () => {
    try {
      const token = await ensureValidToken();
      const userId = localStorage.getItem("userId");
  
      if (!token || !userId) throw new Error("Missing token or user ID");
  
      const headers = {
        Token: token,
        UserId: userId,
        "Content-Type": "application/json",
      };
  
      const requestBody = {
        campaignId: String(1), // Explicitly convert to string
      };
      
  
      const response = await axios.post(OptionView_URL, requestBody, {
        headers,
      });
  
      if (response.data.success && response.data.data.length > 0) {
        const optionData = response.data.data[0].optionData[0];
        setCampaignOptions(optionData);
      } else if (response.data.code === 406) {
        throw new Error("Validation error: Invalid campaign ID.");
      } else {
        throw new Error("No campaign options data available.");
      }
    } catch (error) {
      console.error(
        "Campaign options fetch error:",
        error.response ? error.response.data : error.message
      );
      alert(
        error.response?.data?.message ||
          "Failed to load campaign options. Please check your token and campaign ID."
      );
    }
  };
  

  useEffect(() => {
    fetchData();
    fetchCampaignOptions(); // Fetch OptionView_URL data on component mount
  }, []);

  return (
    <div className="d-flex bg-white flex-column justify-content-center align-items-center">
      {/* Accounts to use */}
      <div className="card card-option w-75 shadow mt-2">
        <div className="p-4">
          <div className="mb-3">
            <h5 className="fw-medium">Accounts to use</h5>
            <p className="text-secondary">
              Select one or more accounts to send emails from
            </p>
          </div>
          <div className="email-box border rounded p-3">
            {emailAccounts.slice(0, showAll ? emailAccounts.length : 5).map((account, index) => (
              <div key={index} className="email-item">
                {account.emailAddress}
              </div>
            ))}
            {!showAll && emailAccounts.length > 5 && (
              <button
                className="btn btn-link text-decoration-none"
                onClick={() => setShowAll(!showAll)}
              >
                +{emailAccounts.length - 5} more
              </button>
            )}
            {showAll && (
              <button
                className="btn btn-link text-decoration-none"
                onClick={() => setShowAll(!showAll)}
              >
                Show less
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Stop sending emails on reply */}
      <div className="card w-75 shadow mt-2">
        <div className="p-4">
          <div className="d-flex gap-2 justify-content-between">
            <div>
              <div className="fw-medium fs-5">Stop sending emails on reply</div>
              <div className="mt-1 fw-medium text-secondary paragraph-option">
                Stop sending emails to a lead if a response has been received
                from
              </div>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="defaultCheck1"
                defaultChecked={campaignOptions.stopEmailOnReplyStatus === "enabled"}
              />
              <label className="form-check-label fw-medium" htmlFor="defaultCheck1">
                Stop on auto-reply
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Other options */}
      {/* Open Tracking, Delivery Optimization, etc. */}
      <div className="card w-75 shadow mt-2">
        <div className="p-4">
          <div className="d-flex gap-2 justify-content-between">
            <div>
              <div className="fw-medium fs-5">Daily Limit</div>
              <div className="mt-1 fw-medium text-secondary paragraph-option">
                Max number of emails to send per day for this campaign
              </div>
            </div>
            <input
              type="number"
              className="form-control input-option"
              id="dailyLimitInput"
              placeholder="Enter limit"
              defaultValue={campaignOptions.dailyLimitCount || ""}
            />
          </div>
        </div>
      </div>

      <div className="d-flex gap-2 mb-2 mt-3">
        <button type="button" className="btn btn-primary">
          <span className="me-1">
            <HiMiniRocketLaunch />
          </span>
          Launch
        </button>
        <button type="button" className="btn btn-secondary">
          <span className="me-1">
            <MdSaveAlt />
          </span>
          Save
        </button>
      </div>
    </div>
  );
}

export default Option;
